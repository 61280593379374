import formattedTime from './formattedTime';
import formattedDate from './formattedDate';
import { isToday, isTomorrow, isYesterday } from 'date-fns';

const formattedTimeAndDate = (dateObj, options = {}) => {
  const { showDate = true, timeZone } = options;

  const time = formattedTime(dateObj, { timeZone });

  const date = isTomorrow(dateObj)
    ? 'tomorrow'
    : isToday(dateObj)
    ? 'today'
    : isYesterday(dateObj)
    ? 'yesterday'
    : `on ${formattedDate(dateObj)}`;

  return [time, showDate ? date : null].filter(Boolean).join(' ');
};

export default formattedTimeAndDate;
