export default {
  DATE_FORMAT: 'M/d/y',
  TIME_FORMAT: 'h:mma',
  TIME_FORMAT_WITH_ZONE: 'h:mma zzz',
  DEFAULT_LAT_LNG: {
    // Dispatch office coordinates
    lat: 44.8331,
    lng: -93.29824
  }
};
