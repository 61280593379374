import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import constants from './constants';

const { TIME_FORMAT, TIME_FORMAT_WITH_ZONE } = constants;

const formattedTime = (dateObj, options = {}) => {
  const { timeZone } = options;

  if (timeZone) {
    return formatInTimeZone(dateObj, timeZone, TIME_FORMAT_WITH_ZONE);
  }

  return format(dateObj, TIME_FORMAT);
};

export default formattedTime;
